import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner, Table } from "react-bootstrap";
import "./styles2.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useGetCards from "./hooks/useGetCards";
import useSubscribeStats from "./hooks/useSubscribeStats";
import useSubscribePro from "./hooks/useSubscribePro";
import useSubscribeFree from "./hooks/useSubscribeFree";
import useUnsubscribe from "./hooks/useUnsubscribe";
import { getToken } from "@globals/authService";
import { usePayer } from "./hooks/usePayer";
import axios, { api } from "@globals/axiosConfig";
import { useSelector } from "react-redux";
import { useModal } from "@/contextProviders/ModalContext";

const LIST_STATUS = [
  { label: "En attendant", value: "pending" },
  { label: "Réussie", value: "payed" },
  { label: "Échoué", value: "not_payed" },
  { label: "Remboursée", value: "refunded" },
  { label: "Annulée", value: "canceled" },
];
const addCardUrl =
  "https://api.cool2scan.com/api/v1/manager/societies/cards/setup";
export default () => {
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.user);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("Abonnement");
  const [{ fetchingCards, Data }, GetCards] = useGetCards();
  const [card, setCard] = useState();
  //id to test in the list for the spinner
  const [idToTest, setIdToTest] = useState();
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState(10);

  const [data, setData] = useState([]);
  const [
    { fetchingPayer, errorPayer, setErrorPayer, successPayer, setSuccessPayer },
    Payer,
  ] = usePayer();
  useEffect(() => {
    user && GetCards();
  }, [user]);
  useEffect(() => {
    if (Data) {
      setCard({
        card_number: Data?.[0]?.last4 ? `************${Data?.[0]?.last4}` : "",
        dateEnd: Data?.[0]?.exp
          ? `${moment(Data?.[0]?.exp).format("MM") || ""}/${
              moment(Data?.[0]?.exp).format("YY") || ""
            }`
          : "",
      });
    }
  }, [Data]);
  const PaymentCard = async (url) => {
    const accessToken = getToken();
    fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }) // FETCH BLOB FROM IT
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        console.log("blob", blob);
        var _url = window.URL.createObjectURL(blob);
        console.log("_url", _url);
        window.location.href = _url; // window.open(_url, "_blank").focus(); // window.open + focus
        // setUrlIframe(_url);
        // setopenModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchData = async (skip) => {
    try {
      const resp = await axios.get(
        `/manager/invoices?$sort={"execution_scheduled_at":"DESC"}&$top=${
          skip || 10
        }`
      );
      setData(resp?.data?.value);
      setCount(resp?.data?.count);
      setPagination(resp?.data?.top);
    } catch {
      // maybe display an error message
    }
  };
  const onPayer = (id) => {
    const payload = {
      selected_card: user?.card?._id,
    };
    Payer(id, payload, () => {
      //refetch the list
      fetchData();
    });
  };
  useEffect(() => {
    user && fetchData();
  }, [activeTab, fetchingPayer, errorPayer, successPayer, user]);

  //subscription logic
  const [
    { errorFree, setErrorFree, successFree, setSuccessFree },
    SubscribeFree,
  ] = useSubscribeFree();
  const [
    { errorStats, setErrorStats, successStats, setSuccessStats },
    SubscribeStats,
  ] = useSubscribeStats();
  const [{ error, setError, success, setSuccess }, Unsubscribe] =
    useUnsubscribe();
  const [
    { fetchingPro, errorPro, setErrorPro, successPro, setSuccessPro },
    SubscribePro,
  ] = useSubscribePro();
  const [total, setTotal] = useState(0);
  const [showModal, setshowModal] = useState(false);
  const [totalTocheck, setTotalTocheck] = useState(0);
  const [totalUnitPrice, setTotalUnitPrice] = useState(15);
  const [subscriptionstate, setSubscriptionstate] = useState({
    $q: "",
    societies: {},
  });
  const getSocieties = async () => {
    const resp = await axios.get(`/manager/societies/`);
    const data = resp?.data;
    if (data) {
      // get the selected value when adding card then return to add codes view
      //localStorage.getItem("subscriptionValue")
      setTotal(
        localStorage.getItem("subscriptionValue")
          ? localStorage.getItem("subscriptionValue")
          : data?.subscription?.pro_codes === 0
          ? 0
          : data?.subscription?.pro_codes
      );
      setTotalTocheck(
        data?.subscription?.pro_codes === 0 ? 0 : data?.subscription?.pro_codes
      );

      setSubscriptionstate({ ...subscriptionstate, societies: data });
    }
  };
  useEffect(() => {
    user && getSocieties();
  }, [
    successPro,
    errorPro,
    successFree,
    errorFree,
    successStats,
    errorStats,
    success,
    error,
    user,
  ]);
  const onSubscribePro = () => {
    //total <= totalTocheck - subscriptionstate?.societies?.count_used_pro_codes)||total>=totalTocheck
    console.log("the user input value = > ", total);
    console.log(
      "used codes => ",
      subscriptionstate?.societies?.count_used_pro_codes
    );
    console.log("les coede acheter => ", totalTocheck);

    const payload = {
      pro_codes: parseInt(total),
      selected_card: user?.card?._id,
    };
    if (!(Data?.length > 0)) {
      setActiveTab("Modes de paiement");
      total && localStorage.setItem("subscriptionValue", total);
    } else {
      if (
        (total >= subscriptionstate?.societies?.count_used_pro_codes &&
          total <= totalTocheck) ||
        total >= totalTocheck
      ) {
        SubscribePro(payload, () => {
          localStorage.setItem("subscriptionValue", "");
        });
      } else {
        // if (total <= totalTocheck) {
        setshowModal(true);
      }
    }
  };
  const onSubscribeFree = (done = () => {}) => {
    const payload = {
      // statistics_enabled: false,
    };
    SubscribeFree(payload, () => {
      setTotalUnitPrice(15);
      done();
    });
  };
  const pro_code_pricing_tranches = [
    { from: 1, to: 5, unit_price: 18 },
    { from: 6, to: 10, unit_price: 17 },
    { from: 11, to: 20, unit_price: 16 },
    { from: 21, to: 50, unit_price: 15 },
    { from: 51, to: 100, unit_price: 14 },
    { from: 101, to: 500, unit_price: 13 },
    { from: 501, to: 1000, unit_price: 12 },
    { from: 1001, to: 5000, unit_price: 11 },
    { from: 5601, to: 10000, unit_price: 10 },
    { from: 10001, to: Infinity, unit_price: 9 },
  ];
  useEffect(() => {
    const tranche = pro_code_pricing_tranches.find(
      (t) => t.from <= total && t.to >= total
    );

    if (tranche) {
      return setTotalUnitPrice(tranche.unit_price * total);
    }
  }, [total]);
  return (
    <Container fluid className="   text-center h-100">
      <Row>
        <Col className="d-flex justify-content-start mt-4 ms-3">
          <h1 className="headerText">{t("Paiement")}</h1>
        </Col>
      </Row>
      {/* tabs header */}
      <Row className="mt-5 ms-1  ">
        <Col
          xs={12}
          md={2}
          onClick={() => {
            user && setActiveTab("Abonnement");
          }}
          className={
            " d-flex justify-content-center align-items-center " +
            (activeTab === "Abonnement"
              ? " tabContainerActive"
              : "tabContainer")
          }
        >
          <h1 className="tabText">{t("Abonnement")}</h1>
        </Col>
        <Col
          xs={12}
          md={2}
          onClick={() => {
            user ? setActiveTab("Modes de paiement") : navigate("/auth");
          }}
          className={
            " d-flex justify-content-center align-items-center " +
            (activeTab === "Modes de paiement"
              ? " tabContainerActive"
              : "tabContainer")
          }
        >
          <h1 className="tabText">{t("Modesdepaiement")}</h1>
        </Col>
        <Col
          xs={12}
          md={2}
          onClick={() => {
            user ? setActiveTab("Historique") : navigate("/auth");
          }}
          className={
            "d-flex justify-content-center align-items-center " +
            (activeTab === "Historique"
              ? "  tabContainerActive"
              : "tabContainer")
          }
        >
          <h1 className="tabText">{t("Historique")}</h1>
        </Col>
      </Row>
      {activeTab === "Abonnement" && (
        <>
          <Row
            className={
              subscriptionstate?.societies?.subscription?.expires_at
                ? " abonnmentContainer0 mt-3"
                : " abonnmentContainer0empty mt-3"
            }
          >
            <Col className="d-flex justify-content-end ">
              {/* TODO */}
              {subscriptionstate?.societies?.subscription?.plan !== "free" && (
                <span
                  onClick={() =>
                    user &&
                    openModal(
                      t("Confirmation"),
                      t("activermodeFree"),
                      () => {
                        onSubscribeFree(() => {
                          closeModal();
                        });
                      },
                      () => {
                        console.log("No clicked");
                      }
                    )
                  }
                  className="text-stop-sub"
                >
                  {user ? t("Arrêtercetabonnement") : ""}
                </span>
              )}
            </Col>
          </Row>
          <Row
            className={
              subscriptionstate?.societies?.subscription?.expires_at
                ? "ms-1 abonnmentContainer "
                : " abonnmentContainerempty ms-1"
            }
          >
            <Col
              xs={12}
              md={6}
              className=" d-flex justify-content-start flex-column"
            >
              <div className="text-curent-sub-Header mt-1 ms-2">
                {t("Abonnementencours")}
              </div>
              <div className="text-curent-sub ms-2">
                {subscriptionstate?.societies?.subscription?.plan === "pro"
                  ? t("PlanPro")
                  : subscriptionstate?.societies?.subscription?.plan === "stats"
                  ? t("Statistique")
                  : subscriptionstate?.societies?.subscription?.plan === "free"
                  ? t("PlanGratuit")
                  : t("PlanGratuit")}
              </div>
            </Col>
            {subscriptionstate?.societies?.subscription?.expires_at && (
              <Col
                xs={12}
                md={6}
                className=" d-flex justify-content-start flex-column "
              >
                <div className="text-curent-sub-Header mt-1 ms-2">
                  {t("Prochainefacturation")}
                </div>
                <div className="text-curent-sub-date mt-1 ms-2">
                  {moment(
                    subscriptionstate?.societies?.subscription?.expires_at
                  ).format("ll")}
                  {" - "}
                  <span className="text-curent-sub-price">{`${subscriptionstate?.societies?.subscription_price} €`}</span>
                </div>
                <div className="text-curent-sub-count mt-1 ms-2">
                  {subscriptionstate?.societies?.subscription?.plan === "pro" &&
                    `${
                      subscriptionstate?.societies?.count_used_pro_codes || "0"
                    } ${t("codeutilisessur")} ${totalTocheck} ${t("achetes")}`}
                </div>
              </Col>
            )}
          </Row>
          {/* CARDS */}
          <Row className=" pb-4  mt-4 abonnmentCardsContainer ">
            <Col
              xs={12}
              md={6}
              className=" d-flex justify-content-start flex-column"
            >
              <div className="cardHeader flex-column d-flex justify-content-center align-items-center">
                <div className="card-Header-text">{t("PlanGratuit")}</div>
                <div className="card-sub-Header-text">{t("Mois0")}</div>
              </div>
              <div className="cardBody flex-column d-flex ">
                <div className="card-body-text mt-3">
                  {t("Creationillimite")}
                </div>
                <div className="card-body-text mt-2">
                  {t("Nombredescansillimite")}
                </div>
                <div className="card-body-text mt-2">
                  {t("Nombredabonnesillimite")}
                </div>
                <div className="card-body-text mt-2 ">
                  {t("Accesauxstatistiques")}
                </div>
              </div>
              <div className=" cardFooter pb-4">
                <div
                  onClick={() => {
                    if (user) {
                      openModal(
                        t("Confirmation"),
                        t("activermodeFree"),
                        () => {
                          onSubscribeFree(() => {
                            closeModal();
                          });
                        },
                        () => {
                          console.log("No clicked");
                        }
                      );
                    } else {
                      navigate("/auth");
                    }
                  }}
                  className="cardFooterBtn mx-4 text-center d-flex justify-content-center align-items-center"
                >
                  <div className="btnText">{t("Souscrire")}</div>
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              className=" d-flex justify-content-start flex-column"
            >
              <div className="cardHeader ForceGreen flex-column d-flex justify-content-center align-items-center">
                <div className="card-Header-text ForceWhite">
                  {t("PlanPro")}
                </div>
                <div className="card-sub-Header-text ForceWhite ">{`${totalUnitPrice}€/Mois`}</div>
              </div>
              <div className="cardBody  ForceGreen flex-column d-flex ">
                <div className="card-body-text ForceWhite mt-3">
                  {t("Creationillimite")}{" "}
                </div>
                <div className="card-body-text mt-2 ForceWhite">
                  {t("Nombredescansillimite")}
                </div>
                <div className="card-body-text mt-2 ForceWhite">
                  {t("Nombredabonnesillimite")}
                </div>
                <div className="card-body-text mt-2 ForceWhite">
                  {t("Envoidemessagesillimite")}
                </div>
                <div className="card-body-text mt-2 ForceWhite">
                  {t("Accesauxstatistiques")}
                </div>
              </div>
              <div className="whiteLigne" />
              <div className=" cardFooter ForceGreen pb-4">
                <div className="card-body-text mt-2 ForceWhite">
                  {t("IndiquezlenombredecodesProsouhaite")}
                </div>
                <div className="textContainer">
                  <input
                    id="inputtotal"
                    type="number"
                    min={0}
                    step={1}
                    value={total}
                    onChange={(e) => {
                      setTotal(e.currentTarget.value.replace(/^0+/, ""));
                    }}
                    autoComplete="off"
                    className="codesInput  mx-auto"
                  ></input>
                </div>
                <div
                  onClick={() => {
                    if (user) {
                      total && onSubscribePro();
                    } else {
                      navigate("/auth");
                    }
                  }}
                  className="cardFooterBtn ForceWhiteBorder mx-4 text-center d-flex justify-content-center align-items-center"
                >
                  <div className="btnText ForceGreenText">{t("Souscrire")}</div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {activeTab === "Modes de paiement" && (
        <>
          <Row className="mt-4 ms-1  ">
            <Col xs={12} md={4} className="">
              <Row>
                <Col className="d-flex justify-content-start mt-3">
                  <div className="FormTitle">{t("numCart")}:</div>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-star mt-1">
                  <div className="textFormContainer">
                    <input
                      id="inputcard"
                      type="text"
                      value={card?.card_number}
                      className="TabFormInput"
                      readOnly
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-start mt-3">
                  <div className="FormTitle">{t("Datedexpiration")}</div>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-star mt-1">
                  <div className="textFormContainer">
                    <input
                      id="inputdateend"
                      value={card?.dateEnd}
                      type="text"
                      readOnly
                      className="TabFormInput"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5 ms-2 pb-5">
            <Col className="d-flex justify-content-start">
              <div
                onClick={() => {
                  user && PaymentCard(addCardUrl);
                }}
                className="btnSave d-flex justify-content-center align-items-center"
              >
                {t("Ajouterunecarte")}{" "}
              </div>
            </Col>
          </Row>
        </>
      )}
      {activeTab === "Historique" && (
        <Row className="abonnmentContainer0 ms-1 mt-5 tableStylesContainer">
          {data?.length === 0 ? (
            <Col>Pas de donner</Col>
          ) : (
            <>
              {" "}
              <table class="table table-hover tableStyles ">
                <tr className="text-start">
                  <th scope="col">ID</th>
                  <th scope="col">{t("Date")}</th>
                  <th scope="col">{t("Montant")}</th>
                  <th scope="col">{t("Statut")}</th>
                </tr>
                <tbody className="mt-3 text-start">
                  {data?.map((item, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <td>{item?.id}</td>
                          <td>
                            {item?.status_info?.pending?.date ? (
                              <div>
                                {moment(
                                  item?.status_info?.pending?.date
                                ).format("DD/MM/YYYY")}
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>{parseFloat(item?.value).toFixed(2)}€</td>
                          <td>
                            {
                              LIST_STATUS.find(
                                (item2) => item2.value === item?.status
                              )?.label
                            }
                          </td>
                          <td>
                            {item?.status === "payed" ? (
                              <a
                                href={`${item?.invoice_pdf}`}
                                // href={`${api}/api/v1/manager/invoices/${item?.id}/receipt`}
                                target="_blank"
                              >
                                {t("Telecharger")}
                              </a>
                            ) : (
                              <span className="text-center">-</span>
                            )}
                          </td>

                          <td>
                            {item?.status === "not_payed" && (
                              <>
                                <div
                                  onClick={() => {
                                    onPayer(item.id);
                                    setIdToTest(item.id);
                                  }}
                                  className="btnSaveTable d-flex justify-content-center align-items-center"
                                >
                                  {fetchingPayer && item.id === idToTest ? (
                                    <Spinner size="sm" />
                                  ) : (
                                    // <span className="text-dark">
                                    t("Payermaintenant")
                                    // </span>
                                  )}
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              {data?.length > 9 && data?.length !== count ? (
                <>
                  <Row className="pb-4">
                    <div
                      className="text-center  my-4"
                      style={{ cursor: "pointer", width: "100%" }}
                    >
                      <span
                        onClick={() => {
                          fetchData(pagination + 10);
                        }}
                        className="trier text-bold text-center"
                        style={{ cursor: "pointer", width: "200px" }}
                      >
                        {t("AfficherPlus")}
                      </span>
                    </div>
                  </Row>
                </>
              ) : (
                <div className="pb-5" />
              )}
            </>
          )}
        </Row>
      )}
    </Container>
  );
};
